import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3ca81776"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "main-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "tishi"
}
const _hoisted_4 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_TableConfig = _resolveComponent("TableConfig")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_text = _resolveComponent("el-text")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_TableFooter = _resolveComponent("TableFooter")!
  const _component_TableBody = _resolveComponent("TableBody")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_Plus = _resolveComponent("Plus")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_upload = _resolveComponent("el-upload")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_dialog = _resolveComponent("el-dialog")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TableBody, null, {
      tableConfig: _withCtx(() => [
        _createVNode(_component_TableConfig, {
          border: $setup.tableConfig.border,
          "onUpdate:border": _cache[0] || (_cache[0] = ($event: any) => (($setup.tableConfig.border) = $event)),
          stripe: $setup.tableConfig.stripe,
          "onUpdate:stripe": _cache[1] || (_cache[1] = ($event: any) => (($setup.tableConfig.stripe) = $event)),
          onRefresh: $setup.doRefresh
        }, {
          actions: _withCtx(() => [
            _createVNode(_component_el_button, {
              type: "primary",
              icon: "PlusIcon",
              onClick: $setup.addList
            }, {
              default: _withCtx(() => [
                _createTextVNode("新增 ")
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["border", "stripe"])
      ]),
      default: _withCtx(() => [
        _withDirectives((_openBlock(), _createBlock(_component_el_table, {
          data: $setup.dataList,
          "header-cell-style": $setup.tableConfig.headerCellStyle,
          size: $setup.tableConfig.size,
          stripe: $setup.tableConfig.stripe,
          border: $setup.tableConfig.border,
          height: $setup.tableConfig.height
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "序号",
              prop: "ID"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "版本号",
              prop: "Version"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "安装包链接",
              prop: "InstallLinkPath"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_text, {
                  class: "mx-1",
                  style: {"cursor":"pointer"},
                  type: "primary",
                  onClick: ($event: any) => ($setup.copyToClipboard(scope.row.InstallLinkPath))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("点击复制")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "上传时间",
              width: "100",
              prop: "CreatedAt"
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "更新说明",
              prop: "Description"
            }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_text, {
                  class: "mx-1",
                  type: "primary",
                  style: {"cursor":"pointer"},
                  onClick: ($event: any) => ($setup.ViewUpdates(scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("点击查看")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "状态",
              prop: "IsCurrent"
            }, {
              default: _withCtx((scope) => [
                (scope.row.IsCurrent)
                  ? (_openBlock(), _createBlock(_component_el_text, {
                      key: 0,
                      class: "mx-1",
                      type: "success"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("发布")
                      ]),
                      _: 1 /* STABLE */
                    }))
                  : (_openBlock(), _createBlock(_component_el_text, {
                      key: 1,
                      class: "mx-1"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("历史")
                      ]),
                      _: 1 /* STABLE */
                    }))
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_el_table_column, {
              align: "center",
              label: "操作",
              fixed: "right",
              width: "180"
            }, {
              default: _withCtx((scope) => [
                (!scope.row.IsCurrent)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      plain: "",
                      type: "primary",
                      size: "small",
                      onClick: ($event: any) => ($setup.onUpdateItem(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode("版本回退")
                      ]),
                      _: 2 /* DYNAMIC */
                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"]))
                  : _createCommentVNode("v-if", true),
                _createVNode(_component_el_button, {
                  plain: "",
                  type: "danger",
                  size: "small",
                  onClick: ($event: any) => ($setup.onDeleteItem(scope.row))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("删除")
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data", "header-cell-style", "size", "stripe", "border", "height"])), [
          [_directive_loading, $setup.tableLoading]
        ])
      ]),
      footer: _withCtx(() => [
        _createVNode(_component_TableFooter, {
          ref: "tableFooter",
          onRefresh: $setup.doRefresh,
          onPageChanged: $setup.doRefresh
        }, null, 512 /* NEED_PATCH */)
      ]),
      _: 1 /* STABLE */
    }),
    _createVNode(_component_el_dialog, {
      modelValue: $setup.dialogVisible,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.dialogVisible) = $event)),
      title: $setup.dialogTitle
    }, {
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          ($setup.showother)
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "primary",
                onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.onSubmitForm()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode("发布")
                ]),
                _: 1 /* STABLE */
              }))
            : _createCommentVNode("v-if", true),
          _createVNode(_component_el_button, {
            onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.onResetForm()))
          }, {
            default: _withCtx(() => [
              _createTextVNode("取消")
            ]),
            _: 1 /* STABLE */
          })
        ])
      ]),
      default: _withCtx(() => [
        _createVNode(_component_el_form, {
          model: $setup.ruleForm,
          rules: $setup.rules,
          ref: "ruleFormRef",
          "label-width": "100px"
        }, {
          default: _withCtx(() => [
            ($setup.showother)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_el_form_item, {
                    label: "版本号",
                    prop: "version"
                  }, {
                    default: _withCtx(() => [
                      _createVNode($setup["ElInput"], {
                        modelValue: $setup.ruleForm.version,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.ruleForm.version) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_el_form_item, {
                    label: "安装包链接",
                    prop: "install_link_path"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_upload, {
                        class: "avatar-uploader",
                        beforeUpload: $setup.beforeUpload
                      }, {
                        default: _withCtx(() => [
                          ($setup.show)
                            ? (_openBlock(), _createBlock(_component_el_text, {
                                key: 0,
                                class: "mx-1",
                                type: "success",
                                style: {"padding":"10px"}
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode("上传成功")
                                ]),
                                _: 1 /* STABLE */
                              }))
                            : (_openBlock(), _createBlock(_component_el_icon, {
                                key: 1,
                                class: "avatar-uploader-icon"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_Plus)
                                ]),
                                _: 1 /* STABLE */
                              }))
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }),
                  (!$setup.show)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, "将安装包拖动到此处上传，也可点击上传，仅支持xxx格式"))
                    : _createCommentVNode("v-if", true)
                ]))
              : _createCommentVNode("v-if", true),
            _createVNode(_component_el_form_item, {
              label: "版本说明",
              prop: "description"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["RichTextEditor"], {
                  ref: "richTextEditor",
                  readonly: !$setup.showother,
                  height: 400,
                  style: {"width":"100%"}
                }, null, 8 /* PROPS */, ["readonly"])
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["model"])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "title"])
  ]))
}